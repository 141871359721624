import { isAdmin } from 'utils/Utils';
import { IUserAccess } from 'types';
import { ReactComponent as CartSvg } from 'Assets/icons/cart.svg';
import { ReactComponent as ZapierSvg } from 'Assets/icons/zapier.svg';
import { ReactComponent as DomainsSvg } from 'Assets/icons/domains.svg';
import { ReactComponent as BillingSvg } from 'Assets/icons/billing.svg';
import { ReactComponent as ProductsSvg } from 'Assets/icons/sideMenu/products.svg';
import { ReactComponent as MailchimpSvg } from 'Assets/icons/mailchimp.svg';
import { ReactComponent as ChangePageBookSvg } from 'Assets/icons/changePageBook.svg';
import { ReactComponent as ChangePageThemeSvg } from 'Assets/icons/changePageTheme.svg';
import { ReactComponent as ChangePageWorldSvg } from 'Assets/icons/changePageWorld.svg';
import { ReactComponent as ChangePagePodcastSvg } from 'Assets/icons/changePodcast.svg';
import { ReactComponent as ChangePageContactsSvg } from 'Assets/icons/changePageContacts.svg';

export const pageTypes = [
  'projects',
  'books',
  'settings',
  'copy-craft',
  'contacts',
  'podcaster',
  'agency',
  'products',
  'domains',
  'domains-senders',
  'mailchimp',
  'billing',
  'billing-settings',
  'zapier',
];

export const menuItems = (userAccess: IUserAccess, isAdmin: boolean) => [
  {
    key: 'projects',
    name: 'Funnels',
    icon: <ChangePageWorldSvg />,
    access: userAccess.funnel,
    redirectUrl: '/console/projects',
  },
  {
    key: 'books',
    name: 'Bookle',
    icon: <ChangePageBookSvg />,
    access: userAccess.bookle,
    redirectUrl: '/console/books',
  },
  {
    key: 'copy-craft',
    name: 'CopyCraft',
    icon: <ChangePageThemeSvg />,
    access: userAccess.copyCraft,
    redirectUrl: '/console/copy-craft',
  },
  {
    key: 'podcaster',
    name: 'Podcaster',
    icon: <ChangePagePodcastSvg />,
    access: userAccess.podcast,
    redirectUrl: '/console/podcaster',
  },
  {
    key: 'contacts',
    name: 'Contacts',
    icon: <ChangePageContactsSvg />,
    access: userAccess.contact,
    redirectUrl: '/console/contacts',
  },
  {
    key: 'products',
    name: 'Products',
    icon: <ProductsSvg />,
    access: userAccess.funnel,
    redirectUrl: '/console/settings/products',
  },
];

export const settingItems = [
  {
    key: 'domains-senders',
    name: 'Domains & Senders',
    icon: <DomainsSvg />,
    access: isAdmin(),
    redirectUrl: '/console/settings/domains-senders',
  },
  {
    key: 'domains',
    name: 'Domains',
    icon: <DomainsSvg />,
    access: true,
    redirectUrl: '/console/settings/domains',
  },
  {
    key: 'billing-settings',
    name: 'Stripe',
    icon: <CartSvg />,
    access: true,
    redirectUrl: '/console/settings/billing-settings',
  },
  {
    key: 'billing',
    name: 'Billing',
    icon: <BillingSvg />,
    access: true,
    redirectUrl: '/console/settings/billing',
  },
  {
    key: 'zapier',
    name: 'Zapier',
    icon: <ZapierSvg />,
    access: true,
    redirectUrl: '/console/settings/zapier',
  },
];
