import styles from './DocumentCard.module.scss';

interface IProps {
  label: string;
  image: string;
  onClick?: () => void;
}

const DocumentCard = ({ image, label, onClick }: IProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.overlay} />
      <img src={image} alt={label} />
      {label}
    </div>
  );
};

export default DocumentCard;
