import { CSSProperties, ElementType } from 'react';

import styles from './IconWithBackground.module.scss';

interface IProps {
  Icon: ElementType;
  color: string;
  size?: number;
  showBackground?: boolean;
}

const IconWithBackground = ({
  Icon,
  color,
  showBackground,
  size = 60,
}: IProps) => {
  const containerStyle: CSSProperties = { width: size, height: size };
  if (showBackground) containerStyle.backgroundColor = color + '33';

  return (
    <div style={containerStyle} className={styles.iconContainer}>
      <Icon fill={color} />
    </div>
  );
};

export default IconWithBackground;
