import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { api } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import QUERIES from 'graphql/queries';
import Link from 'UILib/Link/Link';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import SelectMusic from 'Components/PodcastGenerator/SelectMusicForm/SelectMusicForm';
import { ReactComponent as PodcasterIcon } from 'Assets/icons/Podcaster64.svg';
import { ReactComponent as ArrowLeftIcon } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';
import { getBySocket, generateAiImageBySocket } from 'utils/Utils';
import { IPodcast } from 'types';
import { podcastGenerationPages, PodcastMusicType } from './constants';
import {
  ISection,
  ICoverTemplate,
} from '../PagePodcast/SectionCoverGeneration/SectionCoverGeneration';
import SectionCoverGeneration from '../PagePodcast/SectionCoverGeneration/SectionCoverGeneration';

import styles from './PodcastGenerator.module.scss';

const NewPodcastGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [data, setData] = useState<any>({});
  const [coverImages, setCoverImages] = useState<string[]>([]);
  const [
    defaultCoverTemplate,
    setDefaultCoverTemplate,
  ] = useState<ICoverTemplate>();
  const [isSectionsGenerating, setIsSectionsGenerating] = useState<boolean>(
    false
  );

  const history = useHistory();
  const { search } = useLocation();

  const podcastId = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return queryParams.get('podcastId');
  }, [search]);

  const page = useMemo(() => {
    return podcastGenerationPages[selectedPageIndex];
  }, [selectedPageIndex]);

  useEffect(() => {
    if (podcastId) {
      getPodcastData(podcastId);

      setSelectedPageIndex(1);
    }
  }, [podcastId]);

  const handleChange = (key: string, value: string | string[] | object) => {
    setData({ ...data, [key]: value });
  };

  const handleSettingDefaultCoverTemplate = async (
    podcastId: string,
    templateId: string,
    colorId: string
  ) => {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_PODCAST_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: podcastId,
        colorsSchemeType: String(colorId),
        templateType: String(templateId),
      },
    });
  };
  const handleUploadingPodcastCoverImage = async (podcastId: string) => {
    const imageUrl = data.coverData.image;

    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();

    const request = new FormData();
    request.append('file', imageBlob);
    request.append('id', podcastId);

    await api(
      `${PAGECRAFT_API_URL}/podcasts/upload-image?id=${podcastId}`,
      'POST',
      request
    );
  };

  const handleGenerateCoverImages = async () => {
    // let description = '';
    // if (data.episodeDescription || data.episodeDescription !== '') {
    //   description = `And use this description: ${data.episodeDescription}.`;
    // }

    const ratio = '1:1';
    const prompt = `Make an image that says "${data.podcastName}" that has an element related to this podcast. Make this with a "${data.podcastCoverDesign}" design. If it makes sense, mention "${data.podcastHostName}" on the design. This is a flat image that fills the full frame.`;
    // const prompt = `Generate image that will show ${data.episodeName}. ${description}`;
    const payload = {
      text: prompt,
      imageCount: 4,
      ratio,
    };

    // console.log('IMAGE PROMPT: ', prompt);

    const response = (await generateAiImageBySocket({
      payload,
    })) as any;

    setCoverImages(response.imageUrls);
  };

  const getPodcastData = async (podcastId: string) => {
    const podcastData: IPodcast = await graphQlCall({
      queryTemplateObject: QUERIES.GET_PODCAST_BY_ID,
      headerType: 'USER-AUTH',
      values: { id: podcastId },
    });

    setDefaultCoverTemplate({
      templateId: podcastData.templateType,
      colorSchemeId: podcastData.colorsSchemeType,
    });

    setData({
      ...data,
      podcastName: podcastData.name,
      podcastCoverImage: podcastData.image,
      podcastHostName: podcastData.hostName,
      episodesCount: podcastData.episodesCount,
    });
  };

  const handleGenerateSectionsList = async () => {
    setIsSectionsGenerating(true);
    let description;
    if (data.episodeDescription || data.episodeDescription !== '') {
      description = `Description of this podcast episode is: ${data.episodeDescription}.`;
    }

    const payload = {
      variables: {
        podcastName: data.podcastName,
        episodeName: data.episodeName,
        episodeDescription: description,
        tone: data.episodeTone,
      },

      prompts: [
        {
          key: 'episode',
          prompt: `You are writing script for podcast ${data.podcastName}. Write structure for episode ${data.episodeName} in sections. ${description} For writing use '${data.episodeTone}' tone. Write only sections names. Return format:{"content": {"section_1": "...", }}`,
          output: 'text',
        },
      ],
      jsonMode: true,
    };

    const rawSections = ((await getBySocket({
      emitEventName: 'ai-universal-generate',
      resultEventName: 'ai-universal-generated',
      payload,
    })) as any).text;

    let sections: any = [];
    for (let key in rawSections) {
      let value = rawSections[key];
      sections.push({ title: value, notes: '' });
    }
    setData({ ...data, sections });
    setSelectedPageIndex(selectedPageIndex + 1);
    setIsSectionsGenerating(false);
  };

  const handleCreatePodcast = async (): Promise<string> => {
    const createdPodcastData = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PODCAST_MUTATION,
      headerType: 'USER-AUTH',
      values: { name: data.podcastName, hostName: data.podcastHostName },
    });

    return createdPodcastData._id;
  };

  const handleEpisodeGenerate = async () => {
    try {
      setLoading(true);
      let newPodcastId = podcastId;
      if (!newPodcastId) {
        newPodcastId = await handleCreatePodcast();
        await handleUploadingPodcastCoverImage(newPodcastId);
      }

      //update selected episode cover template
      handleSettingDefaultCoverTemplate(
        newPodcastId,
        data.coverData.id,
        data.coverData.selectedColors
      );

      const seriesData = {
        podcastId: newPodcastId,
        voice: data.selectedVoice,
        name: data.episodeName,
        sections: JSON.stringify(data.sections),
        tone: data.episodeTone,
        music: JSON.stringify(data.musicSample),
        coverData: JSON.stringify(data.coverData),
      };

      const episode = await graphQlCall({
        queryTemplateObject: QUERIES.CREATE_EPISODE_MUTATION,
        headerType: 'USER-AUTH',
        values: seriesData,
      });

      if (episode._id) {
        history.push(`/console/podcaster/episode/${episode._id}/edit`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = async () => {
    if (!page.validation.validate() || loading) return;

    if (selectedPageIndex === 1) {
      handleGenerateSectionsList();
      if (!data.podcastCoverImage) {
        handleGenerateCoverImages();
      } else {
        setCoverImages([data.podcastCoverImage]);
      }

      console.log('defaultCoverTemplate:', defaultCoverTemplate);
    } else {
      setSelectedPageIndex(selectedPageIndex + 1);
    }
  };

  const handlePreviousPage = () => {
    setSelectedPageIndex(selectedPageIndex - 1);
  };

  const handleSectionsChange = (sections: ISection[]) => {
    setData({
      ...data,
      sections: sections,
    });
  };

  if (selectedPageIndex === 4) {
    return (
      <div>
        <SectionCoverGeneration
          onPreviousPage={handlePreviousPage}
          onGenerate={handleEpisodeGenerate}
          title={data.episodeName as string}
          podcastName={data.podcastName}
          episodeNumber={data.episodesCount ? data.episodesCount + 1 : 1}
          sections={data.sections as ISection[]}
          onSectionsChange={handleSectionsChange}
          coverImages={coverImages}
          defaultCoverTemplate={defaultCoverTemplate}
          handleChangeCover={(cover) => setData({ ...data, coverData: cover })}
          loading={loading}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.content}>
          <Link
            className={styles.goBackLink}
            to="/console/podcaster"
            prefixIcon={<ArrowLeftIcon />}
          >
            Dashboard
          </Link>
          <div className={styles.contentContainer}>
            <div className={styles.header}>
              <PodcasterIcon
                width={32}
                height={32}
                fill="#4957d8"
                className={styles.podcasterIcon}
              />
              <div className={styles.title}>{page?.title}</div>
              <div className={styles.subtitle}>{page?.subtitle}</div>
            </div>
            <page.Form
              handleChange={handleChange}
              data={data}
              validation={page.validation}
            />
            <div className={styles.buttonContainer}>
              {selectedPageIndex > (!!podcastId ? 1 : 0) && !loading && (
                <Button
                  appearance="stroke"
                  onClick={handlePreviousPage}
                  prefixIcon={<ArrowLeftIcon />}
                >
                  Previous
                </Button>
              )}
              <Button
                appearance="solid"
                onClick={handleNextPage}
                disabled={loading || isSectionsGenerating}
                postfixIcon={
                  loading || isSectionsGenerating ? (
                    <Loader size={16} color="#ffffff" />
                  ) : (
                    <ArrowRightIcon />
                  )
                }
              >
                {loading || isSectionsGenerating ? '' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
        {selectedPageIndex === 2 && (
          <SelectMusic
            selectedGenres={data.selectedGenres}
            selectedMusicId={data.musicSample?.id}
            handleChange={(item: PodcastMusicType) =>
              handleChange('musicSample', item)
            }
          />
        )}
      </div>
    );
  }
};

export default NewPodcastGenerator;
