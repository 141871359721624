import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  getTemplate,
  getTemplateById,
} from 'CoverEditor/PodcastEpisodesCoverData';
import { getBySocket } from 'utils/Utils';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as ArrowLeft } from 'Assets/arrow-left-back.svg';
import { ReactComponent as PodcastIcon } from 'Assets/icons/podcast.svg';
import SectionGenerationElement from 'UILib/SectionGenerationElement/SectionGenerationElement';
import CoverEditor from 'CoverEditor/CoverEditor';
import ColorScheme from 'UILib/ColorScheme/ColorScheme';
import DragAndDrop from 'UILib/DragAndDrop/DragAndDrop';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Link from 'UILib/Link/Link';

import styles from './SectionCoverGeneration.module.scss';

export interface ISection {
  title: string;
  notes?: string;
}

export interface ICoverTemplate {
  templateId: string;
  colorSchemeId: string;
}

interface IProps {
  title: string;
  episodeNumber: number;
  podcastName: string;
  coverImages: string[];
  sections: ISection[];
  defaultCoverTemplate?: ICoverTemplate;
  onPreviousPage: () => void;
  onGenerate: () => void;
  handleChangeCover: (data: any) => void;
  onSectionsChange: (sections: ISection[]) => void;
  loading: boolean;
}

const width = 400;
const height = 400;
const thumbnailScaleFactor = 0.1666;
const coverEditorScale = 1.0;

const SectionCoverGeneration = ({
  title,
  coverImages,
  onPreviousPage,
  sections,
  onSectionsChange,
  onGenerate,
  handleChangeCover,
  loading,
  episodeNumber,
  defaultCoverTemplate,
  podcastName,
}: IProps) => {
  const [coverDataCatalog, setCoverDataCatalog] = useState<any[]>([]);
  const [coverData, setCoverData] = useState<any>(null);
  const [selectedCoverDataIndex, setSelectedCoverDataIndex] = useState(0);
  const [isNewSectionGenerating, setIsNewSectionGenerating] = useState(false);

  useEffect(() => {
    if (coverImages) {
      const catalog: any = [];
      let index = 0;

      if (defaultCoverTemplate && coverImages.length > 0) {
        const defaultTemplate = getTemplateById(
          defaultCoverTemplate.templateId
        );
        const d = {
          ...defaultTemplate,
          image: coverImages[0],
          index: index,
          selectedColors: Number(defaultCoverTemplate.colorSchemeId),
        };
        catalog.push(d);
        index += 1;
      }

      //process images in to templates
      const _data = getTemplate();
      for (const image of coverImages) {
        for (const data of _data) {
          const d = {
            ...data,
            image: image,
            index: index,
            selectedColors: index % data.colors.length,
          };
          catalog.push(d);
          index += 1;
        }
      }

      setCoverData(catalog[0]);
      setCoverDataCatalog(catalog);
    }
  }, [coverImages]);

  useEffect(() => {
    handleChangeCover(coverData);
  }, [coverData]);

  const handleClickOnCover = (data: any) => {
    setSelectedCoverDataIndex(data.index);
    setCoverData({ ...data });
  };

  const handleClickOnColor = (colors: string[], index?: number) => {
    const data = { ...coverData, selectedColors: index };
    setCoverData(data);
  };

  const handleSectionTitleChange = (indicator: number, value: string) => {
    sections[indicator - 1].title = value;
    onSectionsChange(Array.from(sections));
  };

  const handleSectionNoteChange = (indicator: number, value: string) => {
    sections[indicator - 1].notes = value;
    onSectionsChange(Array.from(sections));
  };

  const handleAddNewSection = async () => {
    setIsNewSectionGenerating(true);
    const response: any = await getBySocket({
      emitEventName: 'ai-single-prompt',
      resultEventName: 'response-ai-single-prompt',
      payload: {
        text: `I have podcast with "${podcastName}" name and an episode in it with "${title}" name. 
        In that episode there are ${sections.length} sections of content and 
        the last one named ${sections[sections.length - 1].title}. 
        Generate a new section name that will be at the end of sections list. Just return only one name option without any additions from your side`,
      },
    });
    const newSections = Array.from(sections);
    newSections.push({ title: response.result.text.replaceAll('"', '') });
    onSectionsChange(newSections);
    setIsNewSectionGenerating(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.scrollContainer}>
          <Link
            to="/console/podcaster"
            prefixIcon={<ArrowLeft />}
            className={styles.backButton}
          >
            Dashboard
          </Link>
          {sections && (
            <div className={styles.sections}>
              <div className={styles.label}>Episode Title</div>
              <div className={styles.title}>{title}</div>
              <div className={styles.label}> Sections</div>
              <DragAndDrop
                droppableId="sections"
                elements={sections}
                onDragEnd={(orderedSections) => {
                  onSectionsChange(orderedSections as ISection[]);
                }}
                draggableNodes={sections.map((section, index) => (
                  <SectionGenerationElement
                    indicator={index + 1}
                    onSectionNoteChange={handleSectionNoteChange}
                    onSectionTitleChange={handleSectionTitleChange}
                    notes={section.notes}
                    text={section.title}
                  />
                ))}
              />
              {isNewSectionGenerating && (
                <div className={styles.loadingContainer}>
                  <div className={styles.loaderContainer}>
                    <Loader size={12} color="#d0d0d0" />
                  </div>
                  Generation in Progress
                </div>
              )}
              <Button
                width={40}
                height={40}
                appearance="stroke"
                className={styles.addButton}
                disabled={isNewSectionGenerating}
                prefixIcon={<PlusIcon />}
                onClick={handleAddNewSection}
              />
            </div>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            width={160}
            height={50}
            appearance="stroke"
            prefixIcon={<ArrowLeft />}
            onClick={onPreviousPage}
            disabled={loading}
          >
            Back
          </Button>
          <Button
            width={160}
            height={50}
            appearance="highlighted"
            postfixIcon={!loading && <PodcastIcon />}
            onClick={onGenerate}
            disabled={loading}
          >
            {loading ? <Loader size={16} color="#ffffff" /> : 'Generate'}
          </Button>
        </div>
      </div>
      <div className={styles.cover}>
        {coverData ? (
          <>
            <div>
              <CoverEditor
                titles={[title, podcastName, `Episode: ${episodeNumber}`]}
                width={width * coverEditorScale}
                height={height * coverEditorScale}
                data={coverData}
                readOnly={true}
              />
              <div className={styles.colorScheme}>
                {coverData.colors &&
                  (coverData.colors as any[]).map((item, i) => (
                    <ColorScheme
                      key={i}
                      colors={item}
                      index={i}
                      onClick={handleClickOnColor}
                      isSelected={i === coverData.selectedColors}
                    />
                  ))}
              </div>
            </div>
            <div className={styles.thumbnails} style={{ height: height }}>
              {coverDataCatalog.map((item, i) => (
                <div className={styles.thumbnail}>
                  {selectedCoverDataIndex === i && (
                    <>
                      <div
                        className={clsx(
                          styles.selectedFrame,
                          styles.zoomFadeAnimate
                        )}
                      />
                      <div className={styles.selectedFrame} />
                    </>
                  )}
                  <CoverEditor
                    titles={[title]}
                    onChangeData={handleClickOnCover}
                    width={width * thumbnailScaleFactor}
                    height={height * thumbnailScaleFactor}
                    readOnly={true}
                    data={item}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>
            <Loader size={24} color="#ffffff" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionCoverGeneration;
