import { ReactNode, useEffect, useState } from 'react';
import {
  Droppable,
  DroppableProps,
  DragDropContext,
  DropResult,
  Draggable,
} from 'react-beautiful-dnd';

interface IProps {
  className?: string;
  droppableId: string;
  elements: unknown[];
  draggableNodes: ReactNode[];
  onDragEnd: (newOrderedElements: unknown[]) => void;
}

const DragAndDrop = ({
  droppableId,
  elements,
  className,
  draggableNodes,
  onDragEnd,
}: IProps) => {
  const handleDragEnd = ({ source, destination }: Partial<DropResult>) => {
    if (!source || !destination || source.index === destination.index) return;

    const newArray = Array.from(elements);
    const selectedElement = newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, selectedElement[0]);
    onDragEnd(newArray);
  };

  return (
    <DragDropContext
      onDragEnd={({ source, destination }) =>
        handleDragEnd({ source, destination })
      }
    >
      <StrictModeDroppable droppableId={droppableId}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            className={className}
            {...provided.droppableProps}
          >
            {draggableNodes.map((node, index) => (
              <Draggable
                key={`${droppableId} - node ${index}`}
                draggableId={`${droppableId} - node ${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {node}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export default DragAndDrop;
