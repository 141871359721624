import { MouseEventHandler, ElementType } from 'react';
import IconWithBackground from 'UILib/IconWithBackground/IconWithBackground';

import styles from './FunnelTypeCard.module.scss';

interface IProps {
  color: string;
  Icon: ElementType;
  title: string;
  subtitle: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const FunnelTypeCard = ({ color, Icon, subtitle, title, onClick }: IProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <IconWithBackground showBackground Icon={Icon} color={color} />
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default FunnelTypeCard;
