import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { fetchUserPodcastsAction } from 'store/podcasts/podcastActions';
import { ReactComponent as PodcasterIconSvg } from 'Assets/icons/Podcaster64.svg';
import { ReactComponent as PodcastPlus } from 'Assets/icons/plusAdd.svg';
import { IPodcast, IPodcastWithTotal } from 'types';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Pagination from 'UILib/Pagination/Pagination';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import StartVideoBlock from 'Components/Common/StartVideoBlock/StartVideoBlock';
import PagePodcastItem from '../PagePodcastItem/PagePodcastItem';

import styles from './PagePodcastDashboard.module.scss';

const sortOptions = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'Podcast Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

interface IProps {
  podcasts: IPodcastWithTotal;
  fetchPodcasts: ({
    search,
    sortBy,
    limit,
    skip,
    isLoading,
    sortAsc,
  }: {
    search?: string;
    sortBy: string;
    limit: number;
    skip: number;
    isLoading: boolean;
    sortAsc: boolean;
  }) => void;
  loading: boolean;
}

const PagePodcastDashboard = (props: IProps) => {
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [searchedText, setSearchedText] = useState<string>('');
  const [podcasts, setPodcasts] = useState<IPodcast[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const history = useHistory();

  const handleCreateNewPodcast = () => {
    history.push(`/console/podcaster/create`);
  };

  useEffect(() => {
    setPodcasts(props.podcasts.podcasts);
    setTotalCount(props.podcasts?.total);
  }, [props.podcasts]);

  const fetchPodcasts = () => {
    props.fetchPodcasts({
      sortBy,
      search: searchedText,
      limit: LIMIT,
      skip: (currentPage - 1) * LIMIT,
      isLoading: !searchedText && !podcasts?.length,
      sortAsc:
        sortOptions.find((item) => item.value === sortBy)?.sortAsc || false,
    });
  };

  useEffect(() => {
    fetchPodcasts();
  }, [searchedText, sortBy, currentPage]);

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    setSearchedText(text);
  };

  return (
    <div className={styles.container}>
      {props.loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : podcasts?.length || searchedText ? (
        <div className={styles.overflowContainer}>
          <div className={styles.contentContainer}>
            <DashboardHeader
              handleAddNewItem={handleCreateNewPodcast}
              buttonText="New Podcast"
              sortOptions={sortOptions}
              sortBy={sortBy}
              handleSort={(value: string) => setSortBy(value)}
              handleSearch={handleSearch}
              title="Your Podcasts"
              total={`${totalCount} Podcasts`}
            />
            <div className={styles.items}>
              {podcasts.map((item, index) => (
                <PagePodcastItem
                  item={item}
                  key={item._id}
                  onDelete={() =>
                    setPodcasts(
                      podcasts.filter((podcast) => podcast._id !== item._id)
                    )
                  }
                  onUpdate={(newPodcast) => {
                    const newPodcastsArray = Array.from(podcasts);
                    newPodcastsArray.splice(index, 1, newPodcast);
                    setPodcasts(newPodcastsArray);
                  }}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalCount / LIMIT)}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : (
        <div className={styles.emptyPodcasts}>
          <PodcasterIconSvg />
          <div>
            <h1 className={styles.podcastTitle}>Welcome to Podcaster</h1>
            <p className={styles.podcastDescription}>
              Please create new podcast to start
            </p>
          </div>
          <Button
            width={200}
            height={50}
            prefixIcon={<PodcastPlus />}
            onClick={handleCreateNewPodcast}
          >
            New Podcast
          </Button>
        </div>
      )}
      <StartVideoBlock
        src="https://cdn.autofunnel.ai/Podcaster+Standalone.mp4"
        name="podcastConsoleVideoPlayed"
        redirectUrl="/console/podcaster/create"
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.podcasts.podcastsLoading,
  podcasts: state.podcasts.items,
});

const mapDispatchToProps = {
  fetchPodcasts: ({
    search,
    sortBy,
    limit,
    skip,
    sortAsc,
    isLoading,
  }: {
    search?: string;
    sortBy: string;
    limit: number;
    skip: number;
    sortAsc: boolean;
    isLoading: boolean;
  }) =>
    fetchUserPodcastsAction({
      search,
      sortBy,
      limit,
      skip,
      isLoading,
      sortAsc,
    }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagePodcastDashboard);
