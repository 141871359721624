import { useState } from 'react';
import { getBySocket, getToken } from 'utils/Utils';
import { IBookChapter } from 'types';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import SectionGenerationElement from 'UILib/SectionGenerationElement/SectionGenerationElement';
import DragAndDrop from 'UILib/DragAndDrop/DragAndDrop';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';

import styles from './BookSections.module.scss';

interface IProps {
  bookTitle: string;
  bookChapters: IBookChapter[];
  onChaptersChange: (newChapters: IBookChapter[]) => void;
}

const BookSections = ({
  bookTitle,
  bookChapters,
  onChaptersChange,
}: IProps) => {
  const [isNewSectionGenerating, setIsNewSectionGenerating] = useState(false);

  const handleAddNewSection = async () => {
    setIsNewSectionGenerating(true);
    try {
      const payload = {
        title: bookTitle,
        chapters: bookChapters.map((chapter) => chapter.title),
        action: 'add',
        token: getToken(),
      };
      const data: any = await getBySocket({
        emitEventName: 'new-book-add-chapter',
        resultEventName: 'response-book-add-chapter',
        payload,
      });
      const newChapters = [
        ...bookChapters,
        { title: data.chapterTitle, comments: [] },
      ];
      onChaptersChange(newChapters);
    } catch (err) {
      console.log(err);
    } finally {
      setIsNewSectionGenerating(false);
    }
  };

  const handleSectionTitleChange = (indicator: number, value: string) => {
    bookChapters[indicator - 1].title = value;
    onChaptersChange(Array.from(bookChapters));
  };

  const handleSectionNoteChange = (indicator: number, value: string) => {
    if (bookChapters[indicator - 1].comments) {
      bookChapters[indicator - 1].comments[0] = value;
    } else {
      bookChapters[indicator - 1].comments = [value];
    }
    onChaptersChange(Array.from(bookChapters));
  };

  return (
    <div className={styles.sections}>
      <div className={styles.label}>Your Title</div>
      <div className={styles.title}>{bookTitle}</div>
      <div className={styles.label}>Sections</div>
      <DragAndDrop
        droppableId="sections"
        className={styles.elementsContainer}
        elements={bookChapters}
        onDragEnd={(orderedSections) => {
          onChaptersChange(orderedSections as IBookChapter[]);
        }}
        draggableNodes={bookChapters.map((chapter, index) => (
          <SectionGenerationElement
            indicator={index + 1}
            onSectionNoteChange={handleSectionNoteChange}
            onSectionTitleChange={handleSectionTitleChange}
            notes={chapter.comments[0]}
            text={chapter.title}
          />
        ))}
      />
      {isNewSectionGenerating && (
        <div className={styles.loadingContainer}>
          <div className={styles.loaderContainer}>
            <Loader size={12} color="#d0d0d0" />
          </div>
          Generation in Progress
        </div>
      )}
      <Button
        width={40}
        height={40}
        appearance="stroke"
        className={styles.addButton}
        disabled={isNewSectionGenerating}
        prefixIcon={<PlusIcon />}
        onClick={handleAddNewSection}
      />
    </div>
  );
};

export default BookSections;
