import React, { useState, useEffect } from 'react';
import { RootState } from '../../store/rootStore';
import { connect } from 'react-redux';
import s from './Billing.module.scss';
import Button from 'UILib/Button/Button';

import { IUserDetails } from 'types';
import { fetchUserDetails } from '../../store/user/userActions';

import { graphQlCall } from '../../graphql/utils';
import QUERIES from '../../graphql/queries';

interface IProps {
  fetchUserDetails: () => void;
  userDetails: IUserDetails;
}

const Billing = (props: IProps) => {
  useEffect(() => {
    props.fetchUserDetails();
  }, []);

  const handleRedirectToStripeCustomerPortal = async () => {
    graphQlCall({
      queryTemplateObject: QUERIES.CREATE_CUSTOMER_PORTAL_SESSION,
      headerType: 'USER-AUTH',
    }).then((data) => {
      if (data.sessionUrl) {
        window.open(data.sessionUrl, '_self');
      }
    });
  };

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h1>Billing</h1>

        <div className={s.line}></div>
        {props.userDetails && (
          <>
            <div className={s.planName}>
              Autofunnel has teamed up with Stripe to streamline billing. <br />
              Click the button below to manage your subscriptions.
            </div>

            <div className={s.buttonContainer}>
              <Button
                width={220}
                height={40}
                onClick={() => {
                  handleRedirectToStripeCustomerPortal();
                }}
              >
                Manage Your Subscriptions
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserDetails(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
