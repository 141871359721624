import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useParams,
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';

import store from './store/rootStore';
import Home from './Pages/PageHome';
import Login from './Pages/PageLogin/PageLogin';
import Signup from './Pages/PageSignup/PageSignup';
import ConfirmEmail from './Pages/PageConfirmEmail';
import Projects from './Pages/PageProjects/PageProjects';
import ResetPassword from './Pages/PageResetPassword/PageResetPassword';
import AlertsBlock from './Components/Alerts';
import SubmitSubscription from './Pages/SubmitSubscription';
import CancelSubscription from './Pages/CancelSubscription';
import Logout from './Pages/PageLogout';
import { AMPLIFY_CLIENT_ID, AMPLIFY_POOL_ID } from './Constants';
import Settings from './Pages/PageSetting/PageSettings';
import PageBooks from './Pages/PageBooks/PageBooks';
import BookBuilder from './Pages/BookBuilder/BookBuilder';
import PageCopyCraft from './Pages/PageCopyCraft/PageCopyCraft';
import AgencyManage from 'Components/Accounts/AgencyManage/AgencyManage';
import ConfirmTransfer from 'Components/Accounts/ConfirmTransfer/ConfirmTransfer';
import CopyCraftTest from 'Components/CopyCraft/CopyCraftChat3';
import PageFinishSignup from './Pages/PageSignup/PageFinishSignup';
import AutoLogin from './Pages/PageLogin/AutoLogin';
import PageSetPassword from './Pages/PageResetPassword/PageSetPassword';
import PageStartResetPassword from './Pages/PageResetPassword/PageStartResetPassword';
import PageUpdatePaymentForm from './Pages/PageUpdatePaymentForm';
import ChoosePlan from './Components/Common/ChoosePlan/ChoosePlan';
import PagePodcast from './Pages/PagePodcast/PagePodcast';
import FunnelBuilder from './Pages/FunnelBuilder/FunnelBuilder';
import PageContacts from './Pages/PageContacts/PageContacts';
import EditPodcastEpisode from './Pages/PagePodcast/EditPodcastEpisode/EditPodcastEpisode';
import NewPodcastGenerator from './Pages/PodcastGenerator/NewPodcastGenerator';
import 'antd/dist/antd.css';
import './App.css';

let awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: AMPLIFY_POOL_ID,
  aws_user_pools_web_client_id: AMPLIFY_CLIENT_ID,
  oauth: {},
};
Amplify.configure(awsconfig);

function App() {
  function ConfirmEmailSlug() {
    let { slug } = useParams<{ slug: string }>();
    return <ConfirmEmail confirmationData={slug} />;
  }

  function SignupEmail() {
    let { email } = useParams<{ email: string }>();
    return <Signup email={email} />;
  }

  function ProjectSlug() {
    let { slug } = useParams<{ slug: string }>();

    if (!slug) {
      return <Projects selectedProjectId={0} />;
    }
    return <Projects selectedProjectId={slug} refresh={true} />;
  }

  return (
    <Provider store={store}>
      <AlertsBlock alerts={store.getState().alerts.items} />
      <Router>
        <Switch>
          {/* Home */}
          <Route exact={true} path="/console">
            <Home />
          </Route>

          {/* Login */}
          <Route exact={true} path="/console/login">
            <Login />
          </Route>

          {/* Signup */}
          <Route exact={true} path="/console/signup/:email">
            <SignupEmail />
          </Route>
          {/* wrong url */}
          <Route exact={true} path="/console/signup">
            <Signup />
          </Route>
          {/* Reset Password */}
          <Route exact={true} path="/console/reset-password">
            <PageStartResetPassword />
          </Route>

          {/* Confirm Email */}
          <Route exact={true} path="/console/confirm-email/:slug">
            <ConfirmEmailSlug />
          </Route>

          {/* Projects */}

          <Route path={`/console/projects/:slug/page/:pageId`}>
            <ProjectSlug />
          </Route>
          <Route path={`/console/projects/:slug/refresh`}>
            <ProjectSlug />
          </Route>
          <Route path={`/console/projects/:slug`}>
            <ProjectSlug />
          </Route>
          <Route path={`/console/projects`}>
            <ProjectSlug />
          </Route>
          <Route path={`/console/purchase`}>
            {/* <Purchase /> */}
            <Redirect to={{ pathname: '/console/login' }} />
          </Route>
          <Route path={`/console/success-subscribed/:session`}>
            <SubmitSubscription />
          </Route>
          <Route path={`/console/cancel-subscribe`}>
            <CancelSubscription />
          </Route>
          <Route path={`/console/logout`}>
            <Logout />
          </Route>
          <Route path={`/console/settings/:option`}>
            <Settings />
          </Route>
          <Route path={`/console/settings`}>
            <Settings />
          </Route>
          <Route path={`/console/books`} exact={true}>
            <PageBooks />
          </Route>
          <Route path={`/console/books/create`} exact={true}>
            <BookBuilder />
          </Route>
          <Route path={`/console/copy-craft`} exact={true}>
            <PageCopyCraft />
          </Route>
          <Route path={`/console/copy-craft/:topicId`}>
            <PageCopyCraft />
          </Route>
          <Route path={`/console/podcaster`} exact={true}>
            <PagePodcast />
          </Route>
          <Route path={`/console/podcaster/create`}>
            <NewPodcastGenerator />
          </Route>
          <Route path="/console/podcaster/episode/:episodeId/edit">
            <EditPodcastEpisode />
          </Route>
          <Route path={`/console/podcaster/:podcastId`}>
            <PagePodcast />
          </Route>
          <Route path={`/console/contacts`} exact={true}>
            <PageContacts />
          </Route>
          <Route path={`/console/contacts/:topicId`}>
            <PageContacts />
          </Route>
          <Route path={'/console/agency'}>
            <AgencyManage />
          </Route>
          <Route path={'/console/confirm-agency-transfer'} exact={true}>
            <ConfirmTransfer />
          </Route>
          <Route path={'/console/test/copy-craft'} exact={true}>
            <CopyCraftTest topicId="" />
          </Route>
          <Route path={'/console/magic-link/:token'} exact={true}>
            <PageFinishSignup />
          </Route>
          <Route path={'/console/autologin'} exact={true}>
            <AutoLogin />
          </Route>
          <Route path={'/console/new-funnel'} exact={true}>
            <FunnelBuilder />
          </Route>
          <Route path={'/console/password-recover/:token'} exact={true}>
            <PageSetPassword />
          </Route>
          <Route path={'/console/frame/update-payment-method'} exact={true}>
            <PageUpdatePaymentForm />
          </Route>
          <Route path={'/console/frame/update-plan'} exact={true}>
            <ChoosePlan />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
