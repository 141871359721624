import { useCallback, useEffect, useMemo, useState } from 'react';
import { Socket } from 'socket.io-client';
import clsx from 'clsx';
import Link from 'UILib/Link/Link';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import Carousel from 'UILib/Carousel/Carousel';
import IconWithBackground from 'UILib/IconWithBackground/IconWithBackground';
import DocumentCard from 'Components/BookBuilder/DocumentCard/DocumentCard';
import { IBookChapter } from 'types';
import { createSocket, getBySocket, getToken } from 'utils/Utils';
import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as PodcastIcon } from 'Assets/icons/podcast.svg';
import { documentGroups, documentPages } from './constants';
import { DocumentType } from './types';
import BookSections from './BookSections/BookSections';
import BookCover from './BookCover/BookCover';

import styles from './BookBuilder.module.scss';

const BookBuilder = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [isNotValidForm, setIsNotValidForm] = useState(false);
  const [data, setData] = useState<Record<string, string>>({});
  const [bookChapters, setBookChapters] = useState<IBookChapter[]>([]);
  const [bookImages, setBookImages] = useState([]);
  const [bookCoverTitles, setBookCoverTitles] = useState<string[]>([]);
  const [
    selectedDocumentType,
    setSelectedDocumentType,
  ] = useState<DocumentType>();

  const isLastPage =
    selectedDocumentType &&
    pageIndex === documentPages[selectedDocumentType].length;

  const documentPage = useMemo(() => {
    if (selectedDocumentType && documentPages[selectedDocumentType]) {
      return documentPages[selectedDocumentType][pageIndex];
    }
  }, [selectedDocumentType, pageIndex]);

  useEffect(() => {
    let socket: Socket;

    if (isLastPage) {
      socket = createSocket();

      socket.emit('generate-openai-image', {
        text: data.bookTitle,
        ratio: '1:1',
        imageCount: 3,
      });

      socket.on('open-ai-image-generated', (response: any) => {
        if (response.imageUrls.length) {
          setBookImages(response.imageUrls);
        }
      });
    }

    return () => {
      if (socket) socket.off('open-ai-image-generated');
    };
  }, [isLastPage, data.bookTitle]);

  const handleValueChange = useCallback((id: string, value: string) => {
    setData((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handleNextClick = async () => {
    if (!documentPage || !selectedDocumentType) return;

    const isNotValid = Object.values(documentPage.fields).some(
      (field) => field.required && !data[field.name]
    );
    setIsNotValidForm(isNotValid);
    if (isNotValid) return;

    if (pageIndex === documentPages[selectedDocumentType].length - 1) {
      await createBookChapters();
      await generateBookCoverTitles();
    }

    setPageIndex((prev) => prev + 1);
  };

  const handlePreviousClick = () => {
    setPageIndex((prev) => prev - 1);
  };

  const generateTextWithPrompt = async (text: string) => {
    const payload = { text };
    const response: any = await getBySocket({
      emitEventName: 'ai-single-prompt',
      resultEventName: 'response-ai-single-prompt',
      payload,
    });
    return response.result.text;
  };

  const generateBookCoverTitles = async () => {
    const subtitle1Prompt = `Write very short slogan for my book that titled "${data.bookTitle}" with description "${data.bookDescription}"`;
    const subTitle1Response = await generateTextWithPrompt(subtitle1Prompt);
    const subtitle2Prompt = `Write one sentence summary for my book that titled "${data.bookTitle}" with description "${data.bookDescription}"`;
    const subTitle2Response = await generateTextWithPrompt(subtitle2Prompt);

    const coverTitles = [
      data.bookTitle,
      subTitle1Response,
      subTitle2Response,
    ].map((title) => {
      return title.replaceAll('"', '');
    });
    setBookCoverTitles(coverTitles);
  };

  const createBookChapters = async () => {
    const payload: any = {
      title: data.bookTitle,
      description: data.bookDescription,
      tone: data.bookTone,
      author: data.authorName,
      token: getToken(),
    };

    if (data.bookAudience) {
      payload.audience = data.bookAudience;
    }
    if (data.bookStructure) {
      payload.structure = data.bookStructure;
    }

    const response: any = await getBySocket({
      emitEventName: 'new-book-table-content',
      resultEventName: 'response-book-table-content',
      payload,
    });
    setBookChapters(
      response.chapters.map((chapter: string) => ({
        title: chapter,
        comments: [],
      }))
    );
  };

  return (
    <div className={styles.pageContainer}>
      <div
        className={clsx(styles.mainContent, {
          [styles.initialPage]: !selectedDocumentType,
        })}
      >
        <Link
          to="/console/books"
          prefixIcon={<ArrowLeft />}
          className={styles.dashboardLink}
        >
          Dashboard
        </Link>
        <div className={styles.pageHeader}>
          {documentPage && (
            <IconWithBackground
              size={60}
              showBackground
              Icon={documentPage.icon}
              color={documentPage.color}
            />
          )}
          {!isLastPage && (
            <>
              <div className={styles.title}>
                {documentPage ? documentPage.title : 'Choose Your Type'}
              </div>
              <div className={styles.subtitle}>
                {documentPage
                  ? documentPage.subtitle
                  : 'Quickly and easy create a book or other document that will meet your needs!'}
              </div>
            </>
          )}
        </div>
        {documentPage || isLastPage ? (
          <>
            {isLastPage ? (
              <BookSections
                bookTitle={data.bookTitle}
                bookChapters={bookChapters}
                onChaptersChange={(newChapters) => setBookChapters(newChapters)}
              />
            ) : (
              documentPage && (
                <documentPage.Form
                  data={data}
                  fields={documentPage.fields}
                  validationFailed={isNotValidForm}
                  onValueChange={handleValueChange}
                />
              )
            )}
            <div className={styles.navButtons}>
              {pageIndex > 0 && (
                <Button
                  prefixIcon={<ArrowLeft />}
                  appearance="stroke"
                  onClick={handlePreviousClick}
                >
                  Previous
                </Button>
              )}
              {isLastPage ? (
                <Button
                  width={160}
                  height={50}
                  appearance="highlighted"
                  postfixIcon={<PodcastIcon />}
                  // onClick={onGenerate}
                  // disabled={loading}
                >
                  {/* {loading ? <Loader size={16} color="#ffffff" /> : 'Generate'} */}
                  Generate
                </Button>
              ) : (
                <Button
                  postfixIcon={<ArrowLeft className={styles.rightIcon} />}
                  appearance="solid"
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              )}
              {documentPage?.optional && (
                <Button appearance="stroke" onClick={handleNextClick}>
                  Skip this Page
                </Button>
              )}
            </div>
          </>
        ) : (
          documentGroups.map((group, index) => (
            <div key={index} className={styles.documentGroup}>
              <div className={styles.header}>
                <IconWithBackground
                  size={30}
                  showBackground
                  Icon={group.icon}
                  color={group.color}
                />
                {group.title}
              </div>
              <div className={styles.documentsList}>
                {group.documents.length <= 6 ? (
                  group.documents.map((document) => (
                    <DocumentCard
                      key={document.type}
                      image={document.image}
                      label={document.label}
                      onClick={() => setSelectedDocumentType(document.type)}
                    />
                  ))
                ) : (
                  <Carousel
                    gap={20}
                    hideIndicator
                    cardsPerSlide={6}
                    cards={group.documents.map((document) => ({
                      image: (
                        <DocumentCard
                          key={document.type}
                          image={document.image}
                          label={document.label}
                        />
                      ),
                    }))}
                    onCardSelect={(documentIndex) => {
                      const selectedType = group.documents[documentIndex].type;
                      setSelectedDocumentType(selectedType);
                    }}
                  />
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {isLastPage && (
        <BookCover
          titles={bookCoverTitles}
          coverImages={bookImages}
          onChange={(data) => console.log(data)}
        />
      )}
    </div>
  );
};

export default BookBuilder;
