import BookAudienceForm from 'Components/BookBuilder/Forms/Book/BookAudienceForm';
import BookInfoForm from 'Components/BookBuilder/Forms/Book/BookInfoForm';
import smallBookImage from 'Assets/images/Book/small-book.png';
import largeBookImage from 'Assets/images/Book/large-book.png';
import middleBookImage from 'Assets/images/Book/middle-book.png';
import whitePaperImage from 'Assets/images/Book/white-paper.png';
import caseStudiesImage from 'Assets/images/Book/case-studies.png';
import marketGuidesImage from 'Assets/images/Book/market-guides.png';
import industryReportsImage from 'Assets/images/Book/industry-reports.png';
import monthlyNewslettersImage from 'Assets/images/Book/monthly-newsletters.png';
import { ReactComponent as BookIcon } from 'Assets/icons/book-icon.svg';
import { ReactComponent as GoToSuccessIcon } from 'Assets/icons/goToSuccess.svg';
import { DocumentForm, DocumentGroup, DocumentType } from './types';

export const documentGroups: DocumentGroup[] = [
  {
    title: 'E-Books',
    icon: BookIcon,
    color: '#4957d8',
    documents: [
      {
        type: 'SmallBook',
        label: 'Small Book',
        image: smallBookImage,
      },
      {
        type: 'MediumBook',
        label: 'Medium Book',
        image: middleBookImage,
      },
      {
        type: 'LargeBook',
        label: 'Large Book',
        image: largeBookImage,
      },
    ],
  },
  {
    title: 'Marketing Materials',
    icon: GoToSuccessIcon,
    color: '#25bb73',
    documents: [
      {
        type: 'WhitePapers',
        label: 'White Papers',
        image: whitePaperImage,
      },
      {
        type: 'MonthlyNewsletters',
        label: 'Monthly Newsletters',
        image: monthlyNewslettersImage,
      },
      {
        type: 'IndustryReports',
        label: 'Industry Reports',
        image: industryReportsImage,
      },
      {
        type: 'CaseStudies',
        label: 'Case Studies',
        image: caseStudiesImage,
      },
      {
        type: 'MarketGuides',
        label: 'Market Guides',
        image: marketGuidesImage,
      },
    ],
  },
];

let bookPages: DocumentForm[];
export const documentPages: Record<DocumentType, DocumentForm[]> = {
  SmallBook: (bookPages = [
    {
      color: '#4957d8',
      title: 'Tell us about your book!',
      subtitle: 'A short description of the product you want to sell',
      icon: BookIcon,
      Form: BookInfoForm,
      fields: {
        bookTitle: {
          name: 'bookTitle',
          label: 'Book Title',
          required: true,
          placeholder: 'How to title your book?',
        },
        authorName: {
          name: 'authorName',
          label: 'Author Name',
          required: true,
          placeholder: "What is the author's name or nickname?",
        },
        bookDescription: {
          name: 'bookDescription',
          label: 'Book Description',
          required: true,
          placeholder: 'Describe what your book is about',
        },
      },
    },
    {
      optional: true,
      color: '#4957d8',
      title: 'Audience & Structure',
      subtitle: 'A short description of the product you want to sell',
      icon: BookIcon,
      Form: BookAudienceForm,
      fields: {
        bookAudience: {
          name: 'bookAudience',
          label: 'Audience',
          placeholder: 'Who is your audience?',
        },
        bookTone: {
          name: 'bookTone',
          label: 'Tone',
          required: true,
          placeholder: 'Select Tone',
        },
        bookStructure: {
          name: 'bookStructure',
          label: 'Structure',
          placeholder: 'Describe what your book is about',
        },
      },
    },
  ]),
  MediumBook: bookPages,
  LargeBook: bookPages,
  WhitePapers: bookPages,
  MonthlyNewsletters: bookPages,
  MarketGuides: bookPages,
  CaseStudies: bookPages,
  IndustryReports: bookPages,
};
