import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Slate, Editable, withReact, RenderElementProps } from 'slate-react';
import {
  Transforms,
  createEditor,
  Node,
  Element as SlateElement,
  Descendant,
  Editor,
} from 'slate';
import { withHistory } from 'slate-history';
import { DispatchType, RootState } from 'store/rootReducer';
import { updateCurrentAudioAction } from 'store/podcasts/podcastActions';
import AudioBlock from 'UILib/AudioBlock/AudioBlock';

import styles from './TextEditor.module.scss';

interface IProps {
  keepDefaultLayout?: boolean;
  initialValue: Descendant[];
  onChange: (newValue: Descendant[]) => void;
  currentPlayingAudio: string | null;
  updateCurrentAudio: (audioKey: string | null) => void;
}

const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) =>
    element.type === 'audio' ? true : isVoid(element);
  return editor;
};

const withLayout = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      for (const [child, childPath] of Node.children(editor, path)) {
        let type: 'paragraph' | 'title' | 'audio';
        const slateIndex = childPath[0];
        const enforceType = (type: 'paragraph' | 'title' | 'audio') => {
          if (SlateElement.isElement(child) && child.type !== type) {
            const newProperties: Partial<SlateElement> = { type };
            Transforms.setNodes<SlateElement>(editor, newProperties, {
              at: childPath,
            });
          }
        };

        switch (slateIndex) {
          case 0:
            type = 'title';
            enforceType(type);
            break;
          default:
            break;
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const TextEditor = ({
  keepDefaultLayout = false,
  initialValue,
  onChange,
  currentPlayingAudio,
  updateCurrentAudio,
}: IProps) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element
        {...props}
        currentPlayingAudio={currentPlayingAudio}
        updateCurrentAudio={updateCurrentAudio}
      />
    ),
    [currentPlayingAudio]
  );

  const editor = useMemo(() => {
    if (keepDefaultLayout) {
      return withEmbeds(withLayout(withHistory(withReact(createEditor()))));
    }

    return withEmbeds(withHistory(withReact(createEditor())));
  }, [keepDefaultLayout]);

  useEffect(() => {
    if (initialValue.length <= 0) return;

    const handleUpdateItems = () => {
      const totalNodes = editor.children;

      initialValue.forEach((value: any, index) => {
        if (totalNodes[index]) {
          const existingNode: any = totalNodes[index];

          if (existingNode.type !== value.type) {
            Transforms.removeNodes(editor, { at: [index] });
            Transforms.insertNodes(editor, value as any, { at: [index] });
          } else {
            if (
              (value.type === 'paragraph' || value.type === 'title') &&
              value.children[0].text !== existingNode.children[0].text
            ) {
              Transforms.insertText(editor, value.children[0].text as any, {
                at: [index],
              });
            } else if (JSON.stringify(existingNode) !== JSON.stringify(value)) {
              Transforms.setNodes(editor, value as any, {
                at: [index],
              });
            }
          }
        } else {
          Transforms.insertNodes(editor, value as any, { at: [index] });
        }
      });

      if (totalNodes.length > initialValue.length) {
        for (let i = totalNodes.length - 1; i >= initialValue.length; i--) {
          Transforms.removeNodes(editor, { at: [i] });
        }
      }
    };

    // handleUpdateItems();
  }, [editor, initialValue]);

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(e) => {
        if (!editor.operations.every((op) => op.type === 'set_selection'))
          onChange(e);
      }}
    >
      <Editable
        renderElement={renderElement}
        className={styles.editor}
        spellCheck
      />
    </Slate>
  );
};

const Element = (
  props: RenderElementProps & {
    currentPlayingAudio: string | null;
    updateCurrentAudio: (audio: string | null) => void;
  }
) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'title':
      return (
        <h2 className={styles.title} {...attributes}>
          {children}
        </h2>
      );
    case 'paragraph':
      return (
        <p className={styles.paragraph} {...attributes}>
          {children}
        </p>
      );
    case 'audio':
      return (
        <div className={styles.audio} contentEditable={false}>
          <AudioBlock
            {...element.data}
            currentPlayingAudio={props.currentPlayingAudio}
            updateCurrentAudio={props.updateCurrentAudio}
            audioId={element.data.id}
          />
          {children}
        </div>
      );
    default:
      return null;
  }
};

const mapStateToProps = (state: RootState) => ({
  currentPlayingAudio: state.podcasts.currentPlayingAudio,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateCurrentAudio: (audio: string | null) =>
    dispatch(updateCurrentAudioAction(audio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
