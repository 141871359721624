import { useEffect, useRef, useState } from 'react';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Input from 'UILib/Input/Input';

import styles from './BookForm.module.scss';

const tones = [
  { label: 'Professional', value: 'professional' },
  { label: 'Semi-Professional', value: 'semi-professional' },
  { label: 'Casual', value: 'casual' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Confident', value: 'confident' },
  { label: 'Empathetic', value: 'emphatic' },
  { label: 'Playful', value: 'playful' },
  { label: 'Authoritative', value: 'Authoritative' },
  { label: 'Custom', value: 'custom' },
];

interface IProps {
  data: Record<string, string>;
  validationFailed: boolean;
  onValueChange: (key: string, value: string) => void;
  fields: Record<
    string,
    { label: string; name: string; placeholder?: string; required?: boolean }
  >;
}

const BookAudienceForm = ({
  data,
  fields,
  validationFailed,
  onValueChange,
}: IProps) => {
  const bookTone = data[fields.bookTone?.name];
  const bookAudience = data[fields.bookAudience?.name];
  const bookStructure = data[fields.bookStructure?.name];

  const [isCustomSelected, setIsCustomSelected] = useState(
    !tones.some((el) => el.value === bookTone) && !!bookTone
  );
  const toneRef = useRef(bookTone);

  useEffect(() => {
    toneRef.current = bookTone;
  }, [bookTone]);

  useEffect(() => {
    return () => {
      if (!toneRef.current) {
        onValueChange(fields.bookTone?.name, 'semi-professional');
      }
    };
  }, []);

  const handleChangeSelect = (value: string) => {
    const isCustom = value === 'custom';
    setIsCustomSelected(isCustom);
    onValueChange(fields.bookTone?.name, !isCustom ? value : '');
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        {fields.bookAudience?.label}
        <Input
          border="stroke"
          className={styles.input}
          placeholder={fields.bookAudience?.placeholder}
          value={bookAudience}
          onChange={(event) =>
            onValueChange(fields.bookAudience?.name, event.target.value)
          }
          error={
            validationFailed && fields.bookAudience?.required && !bookAudience
              ? 'Required Field'
              : undefined
          }
        />
      </div>
      <div className={styles.fieldContainer}>
        {fields.bookTone?.label}
        <Dropdown
          type="stroke"
          label={fields.bookTone?.label}
          onChange={(newValue) => handleChangeSelect(newValue as string)}
          value={isCustomSelected ? 'Custom' : bookTone}
          options={tones}
          hasErrors={validationFailed && fields.bookTone?.required && !bookTone}
        />
        {isCustomSelected && (
          <Input
            border="stroke"
            className={styles.input}
            value={bookTone}
            placeholder="Custom Tone"
            onChange={(e) =>
              onValueChange(fields.bookTone?.name, e.target.value)
            }
          />
        )}
      </div>
      <div className={styles.fieldContainer}>
        {fields.bookStructure?.label}
        <TextArea
          value={bookStructure}
          placeholder={fields.bookStructure?.placeholder}
          onChange={(newValue) =>
            onValueChange(fields.bookStructure?.name, newValue)
          }
          hasErrors={
            validationFailed && fields.bookStructure?.required && !bookStructure
          }
          autoComplete="off"
          height={180}
          maxLength={5000}
        />
      </div>
    </div>
  );
};

export default BookAudienceForm;
