import TextArea from 'UILib/TextArea/TextArea';
import Input from 'UILib/Input/Input';

import styles from './BookForm.module.scss';

interface IProps {
  data: Record<string, string>;
  validationFailed: boolean;
  onValueChange: (key: string, value: string | number | boolean) => void;
  fields: Record<
    string,
    { label: string; name: string; placeholder?: string; required?: boolean }
  >;
}

const BookInfoForm = ({
  data,
  fields,
  validationFailed,
  onValueChange,
}: IProps) => {
  const bookTitle = data[fields.bookTitle?.name];
  const authorName = data[fields.authorName?.name];
  const bookDescription = data[fields.bookDescription?.name];

  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        {fields.bookTitle?.label}
        <Input
          border="stroke"
          className={styles.input}
          placeholder={fields.bookTitle?.placeholder}
          value={bookTitle}
          onChange={(event) =>
            onValueChange(fields.bookTitle?.name, event.target.value)
          }
          error={
            validationFailed && fields.bookTitle?.required && !bookTitle
              ? 'Required Field'
              : undefined
          }
        />
      </div>
      <div className={styles.fieldContainer}>
        {fields.authorName?.label}
        <Input
          border="stroke"
          className={styles.input}
          placeholder={fields.authorName?.placeholder}
          value={authorName}
          onChange={(event) =>
            onValueChange(fields.authorName?.name, event.target.value)
          }
          error={
            validationFailed && fields.authorName?.required && !authorName
              ? 'Required Field'
              : undefined
          }
        />
      </div>
      <div className={styles.fieldContainer}>
        {fields.bookDescription?.label}
        <TextArea
          value={bookDescription}
          placeholder={fields.bookDescription?.placeholder}
          onChange={(newValue) =>
            onValueChange(fields.bookDescription?.name, newValue)
          }
          hasErrors={
            validationFailed &&
            fields.bookDescription?.required &&
            !bookDescription
          }
          autoComplete="off"
          height={180}
          maxLength={5000}
        />
      </div>
    </div>
  );
};

export default BookInfoForm;
